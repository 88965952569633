import Vue from 'vue'
import VueRouter from 'vue-router'
import Store from '@/store'
// import Layout from '@/views/Layout'
// import Login from '@/views/Login'
// import NotFound from '@/views/NotFound'
// import Home from '@/views/Home'
// import Category from '@/views/Category'
// import Cart from '@/views/Cart'
// import My from '@/views/My'
// import GoodsDetail from '@/views/GoodsDetail'
// import Dialog from '@/views/Dialog'
// 动态引入组件的方式，实现路由懒加载
const Login = () => import('@/views/Login')
const Layout = () => import('@/views/Layout')
// const Home = () => import('@/views/Home')
const Category = () => import('@/views/Category')
const Cart = () => import('@/views/Cart')
const My = () => import('@/views/My')
const GoodsDetail = () => import('@/views/GoodsDetail')
const NotFound = () => import('@/views/NotFound')
const Dialog = () => import('@/views/Dialog')

// 新的+
// const MyCard = () => import('@/views/HomeNew/active1-3/mycard')
// const Active1 = () => import('@/views/HomeNew/active1-3/index')
const MyCard = () => import('@/views/HomeNew/mycard')
const AgreeMent = () => import('@/views/HomeNew/agreement')
const Active1 = () => import('@/views/HomeNew/active1/index')
const Active2 = () => import('@/views/HomeNew/active2/index')
const Active3 = () => import('@/views/HomeNew/active3/index')

const Active4 = () => import('@/views/HomeNew/active4/index')
const redDetail = () => import('@/views/HomeNew/active4/redDetail')
const Active5 = () => import('@/views/HomeNew/active5/index')
const Active6 = () => import('@/views/HomeNew/active6/index')
const Active7 = () => import('@/views/HomeNew/active7/index')
const Active8 = () => import('@/views/HomeNew/active8/index/index')
const Active8Log = () => import('@/views/HomeNew/active8/log/index')
const Active8OrderDetails = () => import('@/views/HomeNew/active8/orderDetails/index')
// 地址管理模块
const AddressList = () => import('@/views/address/list/index')
const AddressAdd = () => import('@/views/address/add/index')
// 订单模块
const OrderConfirm = () => import('@/views/order/confirm/index')
const OrderCallback = () => import('@/views/order/callback/index')
// 微信小票
const PayCallback = () => import('@/views/payCallback/index')
// 支付宝红包兑换
const Active9 = () => import('@/views/HomeNew/active9/index')
// 哈尔滨银行微信立减金兑换
const Active10 = () => import('@/views/HomeNew/active10/index')
// 哈尔滨银行支付宝立减金兑换（不需要登录，直接发放）
const Active11 = () => import('@/views/HomeNew/active11/index')
// 哈尔滨银行抖音立减金兑换（不需要登录，直接发放）
const Active12 = () => import('@/views/HomeNew/active12/index')
// 哈尔滨银行美团立减金兑换（不需要登录，直接发放）
const Active13 = () => import('@/views/HomeNew/active13/index')
// 将12抖音立减金兑换与13美团立减金兑换整合到一个页面
const Active1213 = () => import('@/views/HomeNew/active1213/index')

// 12 13领取成功后的页面
const ExchangeSuccess = () => import('@/views/HomeNew/exchangeSuccess/index')

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'layout',
    component: Layout,
    // redirect: '/home',
    redirect: '/active1',
    children: [
      {
        path: 'active1',
        name: 'active1',
        meta: {
          title: '抽奖'
        },
        component: Active1
      },
      {
        path: 'active2',
        name: 'active2',
        component: Active2
      },
      {
        path: 'active3',
        name: 'active3',
        component: Active3
      },
      {
        path: 'active4',
        component: Active4
      },
      {
        path: 'redDetail',
        component: redDetail
      },
      {
        path: 'active5',
        component: Active5
      },
      {
        path: 'active6',
        meta: {
          title: '优惠券领取'
        },
        component: Active6
      },
      {
        path: 'active7',
        meta: {
          title: 'zfb优惠券领取'
        },
        component: Active7
      },
      {
        path: 'active8',
        meta: {
          title: '秒杀活动'
        },
        component: Active8
      },
      {
        path: 'active8/log',
        meta: {
          title: '活动记录'
        },
        component: Active8Log
      },
      {
        path: 'active8/orderDetails',
        meta: {
          title: '订单详情'
        },
        component: Active8OrderDetails
      },
      {
        path: 'mycard',
        meta: {
          title: '我的奖品'
        },
        component: MyCard
      },
      {
        path: 'agreement',
        meta: {
          title: '用户协议'
        },
        component: AgreeMent
      },
      // {
      //   path: 'homeOld',
      //   component: Home
      // },
      {
        path: 'category',
        component: Category
      },
      {
        path: 'cart',
        component: Cart
      },
      {
        path: 'my',
        component: My,
        meta: {
          needLogin: true
        }
      },
      // 地址管理
      {
        path: 'address/list',
        name: 'AddressList',
        meta: {
          title: '地址管理'
        },
        component: AddressList
      },
      {
        path: 'address/add',
        name: 'AddressAdd',
        meta: {
          title: '添加收货地址'
        },
        component: AddressAdd
      },
      // 订单模块
      {
        path: 'order/confirm',
        name: 'OrderConfirm',
        meta: {
          title: '确认订单'
        },
        component: OrderConfirm
      },
      {
        path: 'order/callback',
        name: 'OrderCallback',
        meta: {
          title: '支付结果'
        },
        component: OrderCallback
      },
      // 微信小票
      {
        path: 'payCallback',
        name: 'payCallback',
        meta: {
          title: '支付完成'
        },
        component: PayCallback
      },
      {
        path: 'active9',
        meta: {
          title: '优惠券领取'
        },
        component: Active9
      },
      {
        path: 'active10',
        meta: {
          title: '立减金领取'
        },
        component: Active10
      },
      {
        path: 'active11',
        meta: {
          title: '立减金领取'
        },
        component: Active11
      },
      {
        path: 'active12',
        meta: {
          title: '立减金领取'
        },
        component: Active12
      },
      {
        path: 'active13',
        meta: {
          title: '立减金领取'
        },
        component: Active13
      },
      {
        path: 'active1213',
        meta: {
          title: '立减金领取'
        },
        component: Active1213
      },
      {
        path: 'exchangeSuccess',
        meta: {
          title: '券码'
        },
        component: ExchangeSuccess
      },
    ]
  },
  {
    path: '/detail',
    component: GoodsDetail
  },
  // {
  //   path: '/detail/:proid',
  //   component: GoodsDetail
  // },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/dialog',
    name: 'dialog',
    component: Dialog
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound
  }
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]
const router = new VueRouter({
  // mode: 'history',
  // base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  console.log('to', to)
  const token = Store.state.user.token
  if (to.meta.title) {
    document.title = to.meta.title ? to.meta.title : ''
  }
  if (to.meta.needLogin && !token) {
    // router.push({ path: '/login' }) // 推荐
    // next({ path: '/login' }) // 不推荐 会存在捕捉不到的错误
    router.push({
      name: 'login',
      query: { redirect: to.fullPath } // 登录后再跳回此页面时要做的配置
    })
  } else {
    next()
  }
})
export default router
